/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    h2: "h2",
    a: "a",
    div: "div",
    p: "p",
    h3: "h3",
    strong: "strong",
    br: "br"
  }, _provideComponents(), props.components), {TableOfContents, SideBySide, LandingPageCta} = _components;
  if (!LandingPageCta) _missingMdxReference("LandingPageCta", true);
  if (!SideBySide) _missingMdxReference("SideBySide", true);
  if (!TableOfContents) _missingMdxReference("TableOfContents", true);
  return React.createElement(React.Fragment, null, React.createElement(TableOfContents, {
    tableOfContents: props.data.mdx.tableOfContents
  }), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "fight-hearing-loss-with-the-smallest-and-most-powerful-weapon",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#fight-hearing-loss-with-the-smallest-and-most-powerful-weapon",
    "aria-label": "fight hearing loss with the smallest and most powerful weapon permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Fight hearing loss with the smallest and most powerful weapon"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "All of our senses are important. When letters on a page start to dance or signs become hard to read, we schedule an appointment with our eye doctor to make our vision better. Hearing impairments are no different. In fact, hearing loss is actually more common – affecting more than one in every three Americans. The new invisible hearing aid helps you hear better, in the same way contact lenses help you see better."), "\n", React.createElement(_components.h3, {
    className: "c-md-h3",
    id: "the-invisible-hearing-aid",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#the-invisible-hearing-aid",
    "aria-label": "the invisible hearing aid permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "The invisible hearing aid"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "The hearing aid fits comfortably and discreetly in the ear, making it virtually invisible to the naked eye – but highly noticeable to your sense of hearing. To benefit from this amazing technology, our partner specialist will first take an impression of your ear canal to ensure proper fitting and comfort of the in-the-ear hearing aid. Once the device is properly fitted, get ready to experience vivid and natural sound once again! Frequencies that were once “lost” are now amplified with the help of your new hearing aids."), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "the-advantages-of-in-the-ear-hearing-aids",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#the-advantages-of-in-the-ear-hearing-aids",
    "aria-label": "the advantages of in the ear hearing aids permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "The advantages of in-the-ear hearing aids"), "\n", React.createElement(SideBySide, {
    imgSrc: "https://res.cloudinary.com/dhqvlsb3l/image/upload/f_auto,q_auto:best/v1/assets/na/seo-website/csm_f32edb6d-ite-small_460d6ebc0e.png"
  }, React.createElement(_components.p, {
    className: "c-md-p"
  }, React.createElement(_components.strong, null, "1. Comfortable")), React.createElement(_components.p, {
    className: "c-md-p"
  }, "The hearing aid is individually fitted to the shape of your ear canal, providing a comfortable and secure wearing experience. You won’t see it, you won’t feel it – but you will definitely hear it.\n", React.createElement(_components.br), "\n", React.createElement(_components.br), "\n", React.createElement(_components.strong, null, "2. Invisible")), React.createElement(_components.p, {
    className: "c-md-p"
  }, "The customized shape of an in-the-ear hearing aid vanishes completely in the ear canal. Even the transparent nylon cord, which is for safe removal of your device, is barely visible.\n", React.createElement(_components.br), "\n", React.createElement(_components.br), "\n", React.createElement(_components.strong, null, "3. Natural")), React.createElement(_components.p, {
    className: "c-md-p"
  }, "The in-the-ear hearing aid makes hearing as natural as possible by processing sounds in the ear. The customized venting doesn’t close up the auditory canal completely and provides more natural acoustics.\n", React.createElement(_components.br), "\n", React.createElement(_components.br), "\n", React.createElement(_components.strong, null, "4. Easy")), React.createElement(_components.p, {
    className: "c-md-p"
  }, "During your first appointment, your partner specialist will take an impression of your auditory canal. In approximately one week, you will receive your custom-made device suited to your personal hearing needs, and that’s it! You are done. Improving your quality of life is that easy.")), "\n", React.createElement(LandingPageCta, {
    copy: "START NO-RISK TRIAL",
    classes: "c-cta--content"
  }));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}
